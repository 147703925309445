/*-- -------------------------- -->
<---   type services - wir erstellen        -->
<--- -------------------------- -*/
@media only screen and (min-width: 0px) {
  #type-services {
    padding: var(--sectionPadding);
    background-color: var(--gray-1);
  }
  #type-services .cs-container {
    width: 100%;
    max-width: 54.192rem;
    margin: auto;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  #type-services .cs-title {
    padding-bottom: clamp(2.4rem, 6.7vw, 4rem);
    max-width: 88rem;
    text-align: center;
  }
  #type-services .cs-text {
    padding-bottom: 4.3rem;
    max-width: 88rem;
    text-align: center;
    /* Center text for better alignment */
  }
  #type-services .container-cards {
    max-width: 111.7rem;
    display: grid;
    /* Switch to grid for easier row/column layout */
    grid-template-columns: 1fr;
    /* 1 card per row on small screens */
    gap: clamp(2rem, 5.5vw, 3.2rem);
  }
  #type-services .card {
    background-color: #fff;
    border-radius: 5px;
    padding: clamp(2rem, 4.4vw, 3.2rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6rem;
  }
  #type-services .card .title-card {
    font-size: clamp(1.6rem, 4.4vw, 1.8rem);
  }
  #type-services .card i {
    font-size: clamp(2rem, 6vw, 3.5rem);
    color: var(--primary-2);
    /* Customize icon color */
  }
}
@media only screen and (min-width: 768px) {
  #type-services .container-cards {
    grid-template-columns: repeat(2, 1fr);
    /* 2 cards per row on tablets */
  }
}
@media only screen and (min-width: 1024px) {
  #type-services .cs-container {
    max-width: 130rem;
  }
  #type-services .container-cards {
    grid-template-columns: repeat(3, 1fr);
    /* 3 cards per row on desktops */
  }
}
/*-- -------------------------- -->
  <---       included-services    -->
  <--- -------------------------- -*/
@media only screen and (min-width: 0px) {
  #included-services {
    padding: var(--sectionPadding);
  }
  #included-services .cs-container {
    width: 100%;
    max-width: 60rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: clamp(3.2rem, 6.6vw, 6.8rem);
    align-items: center;
  }
  #included-services .cs-left {
    width: 100%;
    height: auto;
    position: relative;
    align-self: center;
    max-width: 50rem;
  }
  #included-services .cs-left img {
    width: 100%;
    height: auto;
    display: block;
  }
  #included-services .cs-right {
    max-width: 68.9rem;
  }
  #included-services h2 {
    padding-bottom: clamp(3.2rem, 6.6vw, 6rem);
  }
  #included-services .list-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 3rem;
    margin-bottom: 5.2rem;
  }
  #included-services .list-group {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 100%;
  }
  #included-services .list-item {
    width: 100%;
  }
  #included-services .list-item .title-bullet-point {
    display: flex;
    gap: 1rem;
    align-items: center;
    padding-bottom: 1rem;
    font-size: clamp(1.6rem, 3vw, 1.8rem);
    font-weight: bold;
  }
  #included-services .list-item .title-bullet-point i {
    color: var(--primary-2);
  }
  #included-services .list-item picture {
    align-items: center;
    align-self: center;
    display: flex;
  }
  #included-services .cs-button-solid {
    max-width: 34.5rem;
  }
}
@media only screen and (min-width: 610px) {
  #included-services .list-wrapper {
    flex-direction: row;
    align-items: flex-start;
  }
}
@media only screen and (min-width: 1100px) {
  #included-services .cs-container {
    flex-direction: row;
    align-items: flex-start;
    max-width: 130rem;
  }
  #included-services .cs-container .cs-left {
    width: 50%;
  }
  #included-services .cs-container .cs-right {
    max-width: 68.9rem;
    width: 60%;
  }
}
/*-- -------------------------- -->
  <---       Our projects         -->
  <--- -------------------------- -*/
/*-- -------------------------- -->
  <---      sbs uber andesio      -->
  <--- -------------------------- -*/
@media only screen and (min-width: 0em) {
  #sbs-uber-andesio {
    padding: var(--sectionPadding);
  }
  #sbs-uber-andesio .cs-container {
    width: 100%;
    max-width: 55rem;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: clamp(3rem, 3vw, 7.4rem);
  }
  #sbs-uber-andesio .cs-right {
    width: 100%;
    max-width: 48.2rem;
    min-height: clamp(25rem, 68vw, 37.5rem);
    position: relative;
    align-self: center;
  }
  #sbs-uber-andesio .cs-right .cs-picture img {
    width: 100%;
    height: auto;
    display: block;
  }
  #sbs-uber-andesio .cs-left {
    max-width: 64.8rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  #sbs-uber-andesio .cs-left .cs-title {
    width: 100%;
    text-align: left;
    color: var(--headerColor);
    position: relative;
    padding-bottom: clamp(2rem, 6.7vw, 4rem);
  }
  #sbs-uber-andesio .cs-left .p2 {
    padding-bottom: clamp(3rem, 6.7vw, 5.2rem);
  }
  #sbs-uber-andesio .cs-left .services-list {
    list-style: none;
    font-weight: 600;
    line-height: 30;
    margin-bottom: 3rem;
  }
  #sbs-uber-andesio .cs-left .services-list li {
    font-size: clamp(1.6rem, 1.2vw, 1.8rem);
    margin-bottom: 1.6rem;
    display: flex;
    align-items: center;
  }
  #sbs-uber-andesio .cs-left .services-list li picture {
    margin-right: 1.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  #sbs-uber-andesio {
    padding-top: 2rem;
  }
  #sbs-uber-andesio .cs-container {
    flex-flow: row;
    justify-content: space-between;
    gap: 3.25rem;
    max-width: 130rem;
  }
  #sbs-uber-andesio .cs-right {
    max-width: 47.8rem;
  }
}
/*# sourceMappingURL=local.css.map */